import styled from "styled-components";
import {
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledVideoCarousel = styled.div`
  background-color: ${Colors.red};
`;

export const StyledVideoCarouselSwiper = styled.div``;

export const StyledVideoCarouselSlider = styled.div`
  margin-bottom: ${Rem(204)};
`;

export const StyledVideoCarouselSlide = styled.div``;

export const StyledVideoCarouselControls = styled.div``;

export const StyledVideoCarouselControl = styled.div`
  position: absolute;
  top: 100%;
  transform: translateY(-50%);
`;

export const StyledVideoCarouselScrollbar = styled.div``;
